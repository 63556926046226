/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
// import { useIntl } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  // const intl = useIntl();

  const metaDescription = description || site.siteMetadata.description;
  const lang = 'en';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || site.siteMetadata?.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  );
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export const fragment = graphql`
  fragment Seo on STRAPI_ComponentMetaSeo {
    title
    description
    og_image {
      url
      localFile {
        publicURL
      }
    }
  }
`;

export default Seo;
